import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RequestCache } from '@logic-suite/shared/cache';
import { CustomerLookupServiceAdapter } from '@logic-suite/shared/components/lookups';
import { ICustomer } from '@logic-suite/shared/models/customer.model';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { TableService } from '@logic-suite/shared/table';
import { SharedObservable } from '@logic-suite/shared/utils';
import { startOfDay } from 'date-fns';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { ConstantsService } from '../../shared/constants.service';
import { PagedData, PageOptions } from '../../shared/paginator/pager.model';
import { MarkupType } from './contract/surcharge-dialog.component';
import { CostMarkup, SurchargeTemplate } from './contract/surcharge.model';
import {
  Customer,
  CustomerContract,
  CustomerMeteringPoint,
  MeteringPointContract,
  MeterMove,
  MeterRelease,
  UnassignedMeteringPoint,
} from './customer.model';

@Injectable({ providedIn: 'root' })
export class CustomerService implements CustomerLookupServiceAdapter {
  storage = inject(ApplicationStorageService);
  table = inject(TableService);
  dataOptions = {
    totalPages: 1,
    pageStart: 0,
    pageSize: this.storage.getItem('bs-customer.pageSize', 100),
    ...(this.table.hasItem(`bs-customer.filter`) && { filterBy: this.table.getItem(`bs-customer.filter`) }),
  } as PageOptions;

  constructor(
    private http: HttpClient,
    private cache: RequestCache,
    private constants: ConstantsService,
  ) {}

  getCustomers(options: Partial<PageOptions> = {} as PageOptions): Observable<PagedData<Customer>> {
    const opts = Object.assign({ pageStart: 0, pageSize: 100, totalPages: 1 }, options);
    opts.totalPages = 1;
    if (opts.sortDirection) {
      if (opts.sortDirection === 'asc') opts.sortDirection = 'ascending';
      if (opts.sortDirection === 'desc') opts.sortDirection = 'descending';
    }
    if (opts.filterBy && opts.filterBy.length) {
      opts.filterBy = opts.filterBy.map((f) => JSON.stringify(f));
    }

    return this.http.get<PagedData<Customer>>('/api/bs/Customer', { params: opts as any });
  }

  @SharedObservable()
  getCustomer(customerID: string): Observable<Customer> {
    return (
      this.http
        .get<Customer>(`/api/bs/Customer/${customerID}`)
        // TODO: Remove this when `official` is returned by the backend for this endpoint
        .pipe(
          map((c) => {
            if (c.contracts.flatMap((c) => c.meteringPoints).every((mp) => mp!.official === undefined)) {
              c.contracts.forEach((contract) => contract.meteringPoints?.forEach((mp) => (mp.official = true)));
            }
            return c;
          }),
        )
    );
  }

  getGlobalValues() {
    return this.constants.getGlobalValues();
  }

  getCustomerContract(id: string): Observable<CustomerContract> {
    if (!id) return of({} as CustomerContract);
    return this.http.get<CustomerContract>(`/api/bs/Contract/${id}`);
  }

  saveContract(contract: CustomerContract) {
    delete contract.meteringPoints;
    return this.http.post<CustomerContract>('/api/bs/Contract', contract).pipe(
      tap(() => {
        this.cache.invalidate('/api/bs/PeriodicField/');
        this.cache.invalidate(`/api/bs/CostMarkup/${contract.contractID}`);
        this.cache.invalidate(`/api/bs/Customer`);
        this.cache.invalidate(`/api/bs/Contract`);
      }),
    );
  }

  deleteContract(contract: CustomerContract) {
    return this.http.delete(`/api/bs/Contract/${contract.contractID}`).pipe(
      tap(() => {
        this.cache.invalidate(`/api/bs/Customer`);
      }),
    );
  }

  moveMeteringPointsToContract(move: MeterMove): Observable<boolean> {
    return this.http.post<boolean>(`/api/bs/Contract/Move`, move);
  }

  releaseMeteringPoints(released: MeterRelease[]): Observable<boolean> {
    return this.http.post<boolean>(`/api/bs/Contract/Release`, released);
  }

  removeContract(contract: CustomerContract): Observable<boolean> {
    return this.http
      .delete<boolean>(`/api/bs/Contract/${contract.contractID}`)
      .pipe(tap(() => this.cache.invalidate('/api/bs/PeriodicField/')));
  }

  removeMeterFromContract(detail: CustomerMeteringPoint) {
    return this.http.delete(`/api/bs/MeteringPointContract/${detail.meteringPointContractID}`).pipe(
      tap(() => {
        this.cache.invalidate(`/api/bs/Contract/${detail.contractID}`);
        this.cache.invalidate(`/api/bs/MeteringPoint/${detail.meteringPointRegisterID}`);
        this.cache.invalidate(`/api/bs/Customer`); // Needed in order to get the canDelete property
      }),
    );
  }

  getAvailableMeteringPoints(
    fromDateMs: number,
    options = {} as PageOptions,
    toDateMs?: number,
  ): Observable<PagedData<UnassignedMeteringPoint>> {
    const opts: { [key: string]: any } = Object.assign({ pageStart: 0, pageSize: 100, totalPages: 1 }, options);
    opts.totalPages = 1;
    if (opts.sortDirection) {
      if (opts.sortDirection === 'asc') opts.sortDirection = 'ascending';
      if (opts.sortDirection === 'desc') opts.sortDirection = 'descending';
    }
    if (Array.isArray(opts.search)) {
      opts.search = opts.search.filter((s) => s.replace(',', '').trim().length > 1);
      if (opts.search.length == 0) {
        delete opts.search;
      }
    }
    opts.startDateMs = startOfDay(fromDateMs).getTime();
    if (toDateMs) opts.endDateMs = startOfDay(toDateMs).getTime();
    return this.http
      .get<PagedData<UnassignedMeteringPoint>>(`/api/bs/MeteringPoint/Unassigned`, {
        params: opts,
      })
      .pipe(
        map((response) => {
          // Make sure response is reset before delivering it to the component
          response.data.forEach((mp) => delete mp.selected);
          return response;
        }),
      );
  }

  addMeteringPointsToContract(
    startDateMs: number,
    contract: CustomerContract,
    points: UnassignedMeteringPoint[],
    endDateMs?: number,
  ): Observable<boolean> {
    const meteringPoints: MeteringPointContract[] = points.map((p) => ({
      contractID: contract.contractID,
      meteringPointRegisterID: p.meteringPointRegisterID,
      startDateMs,
      endDateMs,
    }));
    return this.http.post<boolean>(`/api/bs/MeteringPointContract/Multiple`, meteringPoints).pipe(
      tap(() => {
        this.cache.invalidate('/api/bs/MeteringPoint/Unassigned');
        this.cache.invalidate(`/api/bs/Customer`);
      }),
    );
  }

  loadSurchargeMatrix(contractID: number, costMarkupType: MarkupType): Observable<CostMarkup[]> {
    return this.http
      .get<CostMarkup[]>(`/api/bs/CostMarkup/${contractID}`, { params: { costMarkupType } })
      .pipe(catchError(() => of([])));
  }

  getSurchargeTemplates(costMarkupType: MarkupType): Observable<SurchargeTemplate[]> {
    return this.http
      .get<SurchargeTemplate[]>(`/api/bs/CostMarkup/Templates`, { params: { costMarkupType } })
      .pipe(catchError(() => of([])));
  }

  loadCorporateBranches(): Observable<ICustomer[]> {
    return of([] as ICustomer[]); // Is not applicable for LBS
  }

  search(val: string, useFilterInsteadOfSearch = !isNaN(Number(val))): Observable<ICustomer[]> {
    return this.getCustomers({
      pageStart: 0,
      pageSize: 10,
      ...(useFilterInsteadOfSearch ? { filterBy: [{ customerID: val }] } : { search: val }),
    }).pipe(
      map((d) => {
        return d.data.map(
          (cust) =>
            ({
              customerID: +cust.customerID,
              name: cust.customerName,
              isGroup: false,
            }) as ICustomer,
        );
      }),
    );
  }
}
